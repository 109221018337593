.main {
  display: flex;
  flex-direction: column;
  margin: 80px 20px;
  align-items: center;
  gap: 40px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.steps {
  max-width: 600px;
}

.step {
  margin: 10px 0;
}

.code {
  background-color: #dedede;
  margin: 10px 0;
}

.details {
  font-size: 14px;
}

.error {
  color: #cc0000;
}

.lillian {
  position: fixed;
  bottom: 10px;
  right: 10px;
  color: #cccccc;
  font-size: 12px;
}

.resTitle {
  padding-top: 10px;
  margin-top: -10px;
  align-self: center;
}

.colTitle {
  text-decoration: underline;
  font-weight: bold;
}

.upload {
  display: flex;
  width: min(30vw, 250px);
  height: min(30vw, 250px);

  padding: 30px;
  justify-content: center;
  align-items: center;

  background-color: #eeeeee;
  border: 1px solid #aaaaaa;
  color: #888888;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
}

.upload:hover {
  background-color: #eaeaea;
}
